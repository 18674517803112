import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import Swipe, { SwipeItem } from "swipejs/react"
import styled, { css } from "styled-components"
import { colors, condition, useBreakpoint } from "../modules/structure/theme"
import { Arrow as _Arrow } from "../modules/structure/theme/arrow.component"
import { flexCenter } from "../modules/structure/theme/styles.component"

const swipeStyle = {
  overflowX: `hidden`,
  alignSelf: `baseline`,
  width: `100%`,
}

const swipeItemStyle = {
  position: `relative`,
  display: `inline-flex`,
}

const ControlBar = styled.div`
  ${flexCenter};
  position: relative;
  counter-reset: bullet;
  width: 100%;
`

const Bullet = styled.div`
  width: 13px;
  height: 13px;
  border-style: solid;
  border-radius: 50%;
  margin: 0 4px;

  ${props =>
    props.bulletType === `number` &&
    css`
      width: 24px;
      height: 24px;
      border-width: 2px;
      border-color: ${colors(`primary`)};
      background-color: ${colors(`active`, `primary`, `white`)};

      &:before {
        counter-increment: bullet;
        content: counter(bullet);
        color: ${colors(`active`, `white`, `primary`)};
        font-weight: 900;
        position: relative;
        top: -1px;
        left: 6px;
      }
    `}

  ${props =>
    props.bulletType === `full` &&
    css`
      border-width: 1px;
      border-color: ${props.foregroundColor};
      background-color: ${props.active ? props.foregroundColor : `transparent`};
    `}
`

const Arrow = styled(_Arrow)`
  ${props =>
    props.arrowType === `far` &&
    css`
      position: absolute;

      ${condition(`left`)`
      left: 10px;
    `}

      ${condition(`right`)`
      right: 10px;
    `}
    `}

  ${props =>
    props.arrowType === `near` &&
    css`
      ${condition(`left`)`
      margin-right: 12px;
    `}

      ${condition(`right`)`
      margin-left: 12px;
    `}
    `}
`

export default function Swiper ({
  children,
  NoSwiperWrapper,
  bulletType = `number`,
  arrowType = `near`,
  foregroundColor,
}) {
  const swipeEl = useRef(null)
  const [position, setPosition] = useState(0)
  const isMobile = useBreakpoint() === `small`

  if (!isMobile) {
    return <NoSwiperWrapper>{children}</NoSwiperWrapper>
  }

  return (
    <>
      <Swipe ref={swipeEl} auto={0} draggable style={swipeStyle} callback={setPosition}>
        {React.Children.map(children, child => (
          <SwipeItem style={swipeItemStyle}>{React.cloneElement(child, { "data-testid": `swiper_item` })}</SwipeItem>
        ))}
      </Swipe>

      <ControlBar data-testid="swiper_control_bar">
        <Arrow
          left
          arrowType={arrowType}
          foregroundColor={foregroundColor}
          onClick={() => swipeEl.current.prev()}
          data-testid="swiper_arrow_left"
        />
        {React.Children.map(children, (_, i) => (
          <Bullet
            bulletType={bulletType}
            foregroundColor={foregroundColor}
            active={position === i}
            onClick={() => swipeEl.current.slide(i)}
            data-testid={`swiper_bullet_${position === i ? `active` : `inactive`}`}
          />
        ))}
        <Arrow
          right
          arrowType={arrowType}
          foregroundColor={foregroundColor}
          onClick={() => swipeEl.current.next()}
          data-testid="swiper_arrow_right"
        />
      </ControlBar>
    </>
  )
}

Swiper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
  NoSwiperWrapper: PropTypes.elementType.isRequired,
  bulletType: PropTypes.oneOf([`number`, `full`]),
  arrowType: PropTypes.oneOf([`near`, `far`]),
  foregroundColor: PropTypes.string,
}
